<template>
  <base-layout>
    <div class="section-header">
      <div class="auditorium-title pt-2 px-3" v-if="room"><h4 class="mb-0">{{ room.name }}</h4></div>

      <div class="pt-0" style="color: #fff">
        <div class="py-2" v-if="item">
          <strong class="title ms-3 me-2 d-block"><span v-if="item.code"> {{ item.code }}  - </span> <span
              v-html="item.title"></span></strong>
          <div class="time ms-3">
            {{ moment(item.start_time).format('dddd HH:mm') }} -
            {{ moment(item.end_time).format('HH:mm') }} {{ timezoneString }}<br>
          </div>
        </div>
        <div v-else class="py-2">
          <strong class="title ms-3 me-2 d-block" v-if="!item">{{ wordings[currentLanguage].STREAM_START_SOON }}</strong>
        </div>
      
      </div>

    </div>


    <ion-content class=" has-header has-subheader has-bottom-banner" id="wcpt-session-detail">
      <div>
        <h3 style="padding:10px;color:red" v-if="isRestrictedAccess">  {{ wordings[currentLanguage].ROOM_NO_ACCESS }}</h3>
        <div class="" v-if="!isRestrictedAccess">
          <div class="container-fluid">
            <div class="row row-cols-1  justify-content-start"
                 :class="{'row-cols-md-2': qandaVisible || votingVisible}">
              <div class="col-12 col-md-8  px-0 content-left" :class="{'col-md-8': qandaVisible || votingVisible}">
                
                
                <div class="card border-0">
                  <div class="card-body py-0">
                    <div class="stream-container" v-if=" user && user.typeName && user.typeName.length > 0">
                      <div class="stream">
                        <iframe
                            v-if="streamLink && user"
                            :src="streamLink || item.liveLink"
                            style="position:absolute;top:0;left:0;width:100%;height:100%;"
                            frameborder="0"
                            allow="autoplay; fullscreen"
                            allowfullscreen class="streamvideo"
                        ></iframe>
                      </div>
                    </div> 
                    <div v-if="loaded  && !user" style="background:red; border:solid  1px red; color: white" class="button col m-3 p-2 text-center rounded" @click="login()"> Bitte loggen Sie sich ein  </div>
                      <div v-if="loaded  && user  && (!user.typeName||  user.typeName.length == 0)" style="background:red; color: white" class="col m-3 p-2 text-center rounded"> Sie haben keinen Zugriff auf diesen Inhalt</div>
                  </div>
                </div>
                <div class="d-md-none" v-if="item">
                  <user-view
                      :session-id="item.id"
                      v-if="loaded && user && user.typeName && user.typeName.length > 0"
                      style="width: 100%;  display:inline-block; vertical-align: top;overflow: hidden;overflow-x:hidden;"
                  ></user-view>
                </div>
                <template v-if="item">
                  <div class="pt-3 px-3 " v-html="item.outline" v-if="item.outline "></div>
                  <div class="px-2">
                    <div class="px-2" style="border:none" v-if="persons && persons.length > 0">
                      <h3 v-if="persons.length > 1" class=" mt-2 mb-0">{{ wordings[currentLanguage].CHAIR_HEADER }}</h3>
                      <h3 v-else class=" mt-2 mb-0">{{ wordings[currentLanguage].CHAIR_HEADER }}</h3>
                    </div>
                    <person-list :persons="persons" v-if="persons && persons.length > 0"></person-list>
                    <div class="px-2" style="border:none" v-if="speakers && speakers.length > 0">
                      <h3 class="mt-2 mb-0">{{ wordings[currentLanguage].SPEAKER_HEADER }}</h3>
                    </div>
                    <person-list :persons="speakers" v-if="speakers && speakers.length > 0"></person-list>

                    <div class="px-2" style="border:none"
                         v-if="presentations && presentations.length > 0">
                      <h3 class=" mt-2 mb-0">{{ wordings[currentLanguage].PRESENTATION_HEADER }}</h3>
                    </div>
                    <presentation-list :presentations="presentations"></presentation-list>
                  </div>


                </template>
              </div>
              <div class="col-12 col-md-4 d-none d-md-block px-0" v-if="item" :class="{'col-md-4': qandaVisible || votingVisible}">
                <div class="card border-0">
                  <div class="card-body p-0 m-0">
                    <IonSegment :value="activeTab" class="interactive-segment">
                      <IonSegmentButton class="interactive-segment-btn"
                                        value="interaction"
                                        @click="changeTab('interaction')"
                      >
                        <IonLabel>{{ wordings[currentLanguage].INTERACTIVE }}</IonLabel>
                      </IonSegmentButton>
                    </IonSegment>
                    <div>
                      <user-view v-on:qanda-active="toggleQanda" v-on:voting-active="toggleVoting"
                                 :session-id="item.id"
                                 v-if="loaded  && user && user.typeName && user.typeName.length > 0"
                                 style="width: 100%;  display:inline-block; vertical-align: top;overflow: hidden;overflow-x:hidden;"
                      ></user-view>
                       <div v-if="loaded  && !user" style="background:red; color: white;  border:solid  1px red; color: red" class="col m-3 p-2 d-sm-none text-center rounded" @click="login()" >  Bitte loggen Sie sich ein </div>
                      <div v-if="loaded  && user  && (!user.typeName||  user.typeName.length == 0)" style="background:red; color: white" class="col p-2 m-3 text-center rounded"> Sie haben keinen Zugriff auf diesen Inhalt</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </base-layout>
</template>

<script>
import {IonContent, IonSegment, IonSegmentButton, IonLabel, alertController} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapState} from "vuex";
import moment from "moment";
import PersonList from "@/components/PersonList";
import PresentationList from "@/components/PresentationList";
import UserView from "@/components/interactive/UserView";
moment.locale("de");

const config = require('@/config')
import {isPlatform} from '@ionic/vue';

export default defineComponent({
  name: "SessionDetail",
  data() {
    return {
      item: null,
      loaded: false,
      isLiveSession: false,
      persons: [],
      speakers: [],
      presentations: [],
      activeTab: "interaction",
      activeSection: 'details',
      interval: null,
      sessionHasStarted: false,
      sessionHasEnded: false,
      liveStreamHasStarted: false,
      liveStreamHasEnded: false,
      liveSessionActive: false,
      zoomSessionActive: false,
      actualTime: "",
      activeSessionsByRoom: [],
      roomStreamMapper: {
        'da7ad0c0-3ed1-4500-1374-080000000006': 'https://vimeo.com/event/1558902/embed/396b9df82c',
        'da7ad0c0-3ed1-4500-1302-080000000008': 'https://vimeo.com/event/1558902/embed',
        'da7ad0c0-3ed1-4500-1302-080000000010': 'https://vimeo.com/event/1558902/embed',
        'da7ad0c0-3ed1-4500-1302-080000000009': 'https://vimeo.com/event/1558902/embed',
        'da7ad0c0-3ed1-4500-1302-080000000011': 'https://vimeo.com/event/1558902/embed',
        'da7ad0c0-3ed1-4500-1302-080000000012': 'https://vimeo.com/event/1558902/embed',
      },
      streamLink: null,
      qandaVisible: false,
      votingVisible: false,
      roomId: null,
      room: null,
      isOnDemandRoom: false,
      isRestrictedAccess: false,
      showsAlert: false,
      utcOffset: '+00:00'
    };
  },
  components: {
    IonContent,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    "person-list": PersonList,
    "presentation-list": PresentationList,
    "user-view": UserView,

  },
  methods: {
    ...mapActions("sessions", [
      "getSingleSession",
      "getPresentationList",
      "getSpeakersInSession",
      "getSessionPersons",
      "getLocation"
    ]),
    
    ...mapActions("cmetracking", [
      "uploadTrackingEvents",
      "trackEvent",
    ]),
    ...mapActions("settings", [
      "getSetting",
    ]),
    ...mapActions("video", ["stopVideos"]),
    async getServerTime() {
      try {
        let time_url =
            "https://mantel-api.mobile.documedias.systems/v2/timestamp";
        return (await this.axios.get(time_url)).data;
      } catch (err) {
        console.log(err);
        return null;
      }
    },
    toggleQanda(status) {
      this.qandaVisible = status;
    },
    toggleVoting(status) {
      this.votingVisible = status;
    },

    getForum(refId) {
      this.$socketInt.emit("getForum", {
        congressId: this.currentConference,
        refId: refId
      });
    },
    checkTime(session) {
      let dateNow;
      let sessionStart;
      let sessionEnd;
      let liveSessionStart;
      let liveSessionEnd;
      let zoomSessionEnd;
      dateNow = new Date(this.actualTime);
      sessionStart = new Date(session.start_time + this.utcOffset);
      sessionEnd = new Date(session.end_time + this.utcOffset);
      this.sessionHasStarted = dateNow > sessionStart;
      this.sessionHasEnded = dateNow > sessionEnd;
      liveSessionStart = moment(sessionStart).subtract(10, "minutes");
      liveSessionEnd = moment(sessionEnd).add(5, "minutes");
      zoomSessionEnd = moment(sessionEnd).add(10, "minutes");
      this.liveStreamHasStarted = dateNow >= liveSessionStart;
      this.liveStreamHasEnded = dateNow >= liveSessionEnd;
      this.liveSessionActive =
          dateNow >= liveSessionStart && dateNow <= liveSessionEnd;
      this.zoomSessionActive =
          dateNow >= liveSessionStart && dateNow <= zoomSessionEnd;
      this.interval = setInterval(() => {
        this.actualTime = moment(this.actualTime).add(5, "seconds");
        dateNow = moment(this.actualTime);
        sessionStart = new Date(session.start_time + this.utcOffset);
        sessionEnd = new Date(session.end_time + this.utcOffset);
        this.sessionHasStarted = dateNow > sessionStart;
        this.sessionHasEnded = dateNow > sessionEnd;

        liveSessionStart = moment(sessionStart).subtract(10, "minutes");
        liveSessionEnd = moment(sessionEnd).add(5, "minutes");
        zoomSessionEnd = moment(sessionEnd).add(10, "minutes");
        this.liveStreamHasStarted = dateNow >= liveSessionStart;
        this.liveStreamHasEnded = dateNow >= liveSessionEnd;
        this.liveSessionActive =
            dateNow >= liveSessionStart && dateNow <= liveSessionEnd;
        this.zoomSessionActive =
            dateNow >= liveSessionStart && dateNow <= zoomSessionEnd;
        /*console.log("start", liveSessionStart.format());
        console.log("end", liveSessionEnd.format());
        console.log("now", moment(dateNow).format());
        console.log("Session is live", this.liveSessionActive);*/
      }, 5000);
    },

    checkRestriction() {
      let now = new moment().toISOString();
      if (this.user && this.user.extra && this.user.extra[this.currentConference]) {
        let extra = this.user.extra[this.currentConference];
        //let full = extra.tn_category == 'Full';
        let pfd = extra.tn_category_short == 'pfd';
        let virtsat = extra.tn_category_short == 'virtsat';
        let qm = extra.tn_category_short == 'qm';
        let pfdSessions = ['da7ad0c0-3ed1-4500-1302-010000000027', 'da7ad0c0-3ed1-4500-1302-010000000028', 'da7ad0c0-3ed1-4500-1302-010000000029', 'da7ad0c0-3ed1-4500-1302-010000000030', 'da7ad0c0-3ed1-4500-1302-010000000031'];
        let qmSessions = ['da7ad0c0-3ed1-4500-1302-010000000152', 'da7ad0c0-3ed1-4500-1302-010000000156', 'da7ad0c0-3ed1-4500-1302-010000000158', 'da7ad0c0-3ed1-4500-1302-010000000159'];
        this.isRestrictedAccess = false;
        if (this.item != null && this.item.id) {
          if (pfd && pfdSessions.indexOf(this.item.id) == -1) {
            this.isRestrictedAccess = true;
          }
          if (qm && qmSessions.indexOf(this.item.id) == -1) {
            this.isRestrictedAccess = true;
          }
          if (virtsat && this.item.start_time.indexOf('2022-03-19T') == -1) {
            this.isRestrictedAccess = true;
          }
        } else {

          if (pfd || qm) {
            this.isRestrictedAccess = true;
          }
        }
        if (virtsat && now.indexOf('2022-03-19T') == -1) {
          this.isRestrictedAccess = true;
        }

      }
    },
    async showDisclaimer(session) {

      if (session.disclaimer && session.disclaimer.length > 10) {
        if (!this.showsAlert) {
          this.showsAlert = true;
          const alert = await alertController
              .create({
                cssClass: 'my-custom-class',
                header: session.title,
                message: '<strong>' + session.disclaimer + '</strong>',
                buttons: [
                  {
                    text: 'Decline',
                    role: 'cancel',
                    cssClass: 'secondary',
                    id: 'cancel-button',
                    handler: () => {
                      this.showsAlert = false;
                      this.$router.push('/app/dasboard');
                    },
                  },
                  {
                    text: 'Accept',
                    id: 'confirm-button',
                    handler: () => {
                      this.showsAlert = false;

                    },
                  },
                ],
              });
          return alert.present();
        }
      }
    },

    async fetchSession(sessid) {

      this.item = await this.getSingleSession(sessid)
      await this.showDisclaimer(this.item)
      await this.checkRestriction();
      let trackData = {
        user: this.user,
        item: this.item,
        event: 'enter',
        type: 'session',
        itemId: this.item.id,
      }
      this.persons = await this.getSessionPersons(this.item.id);
      this.speakers = await this.getSpeakersInSession(this.item.id);
      this.presentations = await this.getPresentationList(this.item.id);
      //console.log('before track', this.isRestrictedAccess)
      if (!this.isRestrictedAccess) {
        //console.log('TRACK user', trackData)
        this.trackEvent(trackData)
      }


      this.getForum(sessid);
      let links;
      try {
        let external = this.item.external_link.replace(/'/g, '"');
        links = JSON.parse(external);
      } catch (err) {
        links = this.item.external_link;
      }

      this.item.liveLink = links.live;
      this.item.odLink = links.od;
      this.actualTime = await this.getServerTime();
      this.checkTime(this.item);


    },
    joinChannel() {
      this.$socketInt.emit("join", this.currentConference);
    },
    getActiveSessionsByRoom() {
      this.$socketInt.emit("getActiveSessionsByRoom", {
        congressId: this.currentConference,
        roomId: this.roomId,
      });
    },

     async login() {
      console.log("login")
      let self = this
      localStorage.setItem('lastPageBeforeLogin', location.hash);
      //let frontendKey = config.auth_key;
      if (isPlatform('ios') && typeof cordova != 'undefined') {
        let ref = cordova.InAppBrowser.open("https://auth.documedias.com/application/" + config.auth_dev_key + '?logout=true', '_blank', 'location=yes'); // eslint-disable-line

        let redirect = (param) => {
          if (param && param.indexOf('token=') != -1) {
            let token = param.split('token=')[1];
            if (token) {
              self.loginByToken(token);
              this.loginAlertOpen = false;
              ref.close();
            }
          }
        }

        ref.addEventListener('loaderror', function (event) {
          ref.executeScript({code: "document.redirectLink = (document.getElementById('redirect').href);"}, ((link) => {
            redirect(link.toString());
          }));
          if (event.url.indexOf('token=')) {
            let token = event.url.split('token=')[1];
            if (token) {
              self.loginByToken(token);
              this.loginAlertOpen = false;
              ref.close();
            }
          }
        });
        ref.addEventListener('loadstart', function (event) {
          console.log('PARAM', event)
          /*ref.executeScript({code: "document.redirectLink = (document.getElementById('redirect').href);"}, ((link) => {
            redirect(link.toString());
          }));*/
          console.log('PARAM', event.url)
          if (event.url.indexOf('token=')) {
            let token = event.url.split('token=')[1];
            if (token) {
              self.loginByToken(token);
              this.loginAlertOpen = false;
              ref.close();
            }
          }
        });




      } else if (isPlatform('android') && typeof cordova != 'undefined') {

        let ref = cordova.InAppBrowser.open("https://auth.documedias.com/application/" + config.auth_dev_key, '_blank', 'location=yes', 'clearcache=no', 'clearsessioncache=no'); // eslint-disable-line
        ref.addEventListener('loaderror', function (event) {

          if (event.url.indexOf('token=')) {
            let token = event.url.split('token=')[1];
            if (token) {
              self.loginByToken(token);
              this.loginAlertOpen = false;
              ref.close();
            }
          }
        });
      } else {
        console.log("essta")
        //window.location.href = "https://auth.documedias.com/application/" + config.auth_dev_key
        window.location.href = "https://auth.documedias.com/application/" + (process.env.NODE_ENV == 'production' ? config.auth_key : config.auth_dev_key) + '?logout=true'

      }

    },

  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapState('currentConference', ['currentConference', 'fullName', 'shortName', 'apiVersion']),
    ...mapState('wordings', ['wordings', 'currentLanguage']),


  },

  async created() {

    this.roomId = this.$route.params.id;
    if (this.roomId == 'da7ad0c0-3ed1-4500-1302-080000000014' || this.roomId == 'da7ad0c0-3ed1-4500-1302-080000000013') {
      this.isOnDemandRoom = true;
    }

    this.joinChannel();
    this.getActiveSessionsByRoom();
    this.checkRestriction();
    this.$socketInt.on("getActiveSessionsByRoom", res => {
      //console.log(res);

      if (res && res.room == this.roomId) {
        this.activeSessionsByRoom = [];
        for (let i = 0; i < res.sessions.length; i++) {
          this.activeSessionsByRoom.push(res.sessions[i].sessionId)
        }
        if (this.activeSessionsByRoom.length > 0) {
          let _id = this.activeSessionsByRoom[0];
          this.fetchSession(_id);
        } else {
          if (this.item) {

            //if (!this.isRestrictedAccess) {
            let trackData = {
              user: this.user,
              item: this.item,
              event: 'leave',
              type: 'session',
              itemId: this.item.id,
            }
            this.trackEvent(trackData);

            //}
            this.isRestrictedAccess = false;
          }
          this.checkRestriction()


          this.item = null;
          this.persons = null;
          this.speakers = null;
          this.presentations = null;

          console.log('no active')
        }

      } else {
        console.log('no res')
        if (this.item && !this.isRestrictedAccess) {
          let trackData = {
            user: this.user,
            item: this.item,
            event: 'leave',
            itemId: this.item.id,
            type: 'session'
          }
          this.trackEvent(trackData)
        }
      }
    });
  },
  watch: {
    '$route': {
      immediate: true,
      async handler(to) {
        if (to.name == 'Live Room') {
          this.moment = moment;
          this.utcOffset = await this.getSetting('timezone');
          this.roomId = to.params.id;
          this.room = await this.getLocation(to.params.id);
          console.log(this.room)
          this.streamLink = this.room.stream_link ? this.room.stream_link : this.roomStreamMapper[this.roomId];

          if (this.streamLink.indexOf('autoplay') != -1) {
            console.log('AUTOPLAY')
            if (this.streamLink.indexOf('autoplay=0') != -1) {
              //this.streamLink = this.streamLink.replace('autoplay=0', 'autoplay=1')
            }

          }
          this.joinChannel();
          this.getActiveSessionsByRoom();
          this.checkRestriction();
          this.loaded = true;
          this.uploadTrackingEvents();
        } else {
          if (this.item && this.item.id) {
            let trackData = {
              user: this.user,
              item: this.item,
              event: 'leave',
              itemId: this.item.id,
              type: 'session'
            }
            this.trackEvent(trackData)

          }
          this.streamLink = null;
          this.uploadTrackingEvents();
          this.stopVideos();
        }
      }
    }
  },
});
</script>

<style lang="scss" scoped>
.content-left {
  .action-buttons {
    .actn-icon {
      font-size: 1.3rem !important;
    }

    .actn-text {
      font-size: 0.7rem !important;
      line-height: 0.8rem !important;
    }
  }

  .stream-container {
    width: 100%;
    //max-width: 1100px;

    .stream {
      position: relative;
      width: 100%;
      aspect-ratio: 16/9;

      .info-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 3px dashed grey;

        .info {
          flex: 0 0 400px;
          font-size: 2rem;
          line-height: 2.2rem;
          color: grey;
          font-weight: bold;
        }
      }
    }
  }


}
</style>
